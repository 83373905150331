
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {};
  },computed:{
    list(){
      return this.$store.state.list
    }
  }

});
