
import { defineComponent} from 'vue';
export default defineComponent({
    data(){
        return{

        }
    },
    methods:{
        incCount():void{
            this.$store.commit("incCount")
        }
    }
})
