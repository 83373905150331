import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'
//引入组件
import Home from "./components/Home.vue"
import News from "./components/News.vue"
import User from "./components/User.vue"

//配置路由
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes: [
        { path: '/', component: Home },
        { path: '/news', component: News },
        { path: '/user', component: User },
    ],
})

export default router
