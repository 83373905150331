<template>
    <div style="text-align: center;width: 100%; height: 90%">
        <ul style="width: 50%;margin: 0 auto;">
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 24px;">静下心来~</span><br/>
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 18px;">每天都在上下班的路途上奔波，每天都在996，每天都在重复。</span>
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 18px;">刚参加工作的时候想月薪过万的话就要天天喝星巴克，后来发现瑞幸也不错，再后来发现还是雀巢比较实惠。不是星巴克涨价了，而是身上的担子变重了，家里开支、房贷、幼儿园。没有了锐气，只剩下工作。</span>
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 18px;">抽空小憩一下，生活还是要像好的方向看，整理了自己工作中的经验，文档是实操后整理的。</span>
            </p>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 18px;">向所有的程序员致敬。</span>
            </p>
        </ul>
        <div style="height: 200px;">
            <img :src="xinkule"  style="height: 100%"/>
        </div>
    </div>
    <div class="footer"><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023004491号-1</a> |
        <img :src="beian" /> <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051958">京公网安备 11010502051958号</a></div>
    <!--<div style="width:300px;margin:0 auto; padding:20px 0;">-->
        <!--<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41102402000277" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">-->
            <!--<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京ICP备2023004491号-1</p></a>-->
    <!--</div>-->

</template>

<script >
    import { defineComponent} from 'vue';
    import xinkule from '@/assets/xinkule.jpg';
    import beian from '@/assets/beianIcon.png';
    export default defineComponent({
        data(){
            return{
                xinkule:xinkule,
                beian: beian
            }
        },

    })
</script>

<style lang="scss">
    html,body { height:95%;}
    .wrap { position:relative; height:auto; min-height:100%;}
    .content { padding-bottom:60px; height:auto; background:yellow;}
    .footer { width:100%; height:60px;   left:0; position:absolute; bottom:0;}
</style>
