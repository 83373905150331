// import {
//   createStore
// } from 'vuex'

// import newsStore from "./newsStore"
// import userStore from "./userStore"
// const store = createStore({
//   modules:{
//     "user":userStore,
//     "news":newsStore
//   }
// })

// export default store;

//配置vue+ts的项目里面使用vuex
//https://next.vuex.vuejs.org/guide/typescript-support.html#typing-store-property-in-vue-component
import { Store,createStore} from 'vuex'
declare module '@vue/runtime-core' {
  // declare your own store states
  interface State {
    count: number,
    list:string[],
    msg:string
  }

  // provide typings for `this.$store`
  interface ComponentCustomProperties {
    $store: Store<State>
  }
}

const store = createStore({
    state(){ //数据
        return{
            count:1,
            list:["马总","李总"],
            msg:"你好vue"
        }
    },
    mutations:{ //方法 
        incCount(state:any){
            state.count++           
        },
        setCount(state:any,num:number){
            state.count=num
        },
        setMsg(state:any,msg:string){
            state.msg=msg
        }
    },getters:{  //计算属性
        reverseMsg(state:any){
            return state.msg.split("").reverse().join("")
        },
        num(state:any):number{
            return state.count+10
        }
    },actions:{  //执行mutations里面的方法  异步操作放在actions
        incCount(context){
            context.commit("incCount")
        },
        setMsg({commit},msg:string){
            setTimeout(()=>{
                commit("setMsg",msg) 
            },1000)
        }
    }
})

export default store